const isTextureJsonFile = ( contents: string ): boolean => {
    try {
        const json = JSON.parse( contents );

        return json.id && json.name && json.author && json.pages;
    }
    catch( e ) {
        return false;
    }
};

const isTextureHtmlFile = ( contents: string ): boolean => {
    return contents.includes( "texture.start(" );
};

const isTextureFile = ( contents: string, extension: string ): boolean => {
    switch( extension ) {
        case "json":
        case "texture":
            return isTextureJsonFile( contents );

        case "htm":
        case "html":
            return isTextureHtmlFile( contents );

        default:
            return false;
    }
};

export default isTextureFile;
