import React, { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";
import Tooltip from "react-bootstrap/Tooltip";

import "./GameCard.scss";

import GameCardActionsDropdown from "./GameCardActionsDropdown";

import { getCoverThumbnailUrls } from "../../services/firebase";
import { getPlayUrl } from "services/firebase";
import { PublicGameData } from "../../types/game";
import { getGenreLabel } from "services/genres";

interface GameCardProps {
    game: PublicGameData;
    id: string;
}

// adapted from https://stackoverflow.com/a/3426956
function idToRGB( str: string ): string {
    let hash = 0;
    const len = str.length;

    for( let i = 0; i < len; i++ ) {
        hash = str.charCodeAt( i ) + ( ( hash << 5 ) - hash );
    }

    const c = ( hash & 0x00FFFFFF )
        .toString( 16 )
        .toUpperCase();

    return "#00000".substring( 0, 7 - c.length ) + c;
}


/**
 * One game in a listing
 */
const GameCard: React.FC<GameCardProps> = ({ game, id }) => {
    const [ coverUrls, setCoverUrls ] = useState<string[] | null>( null );
    const gradientFrom = idToRGB( id.substr( 0, 4 ) );
    const gradientTo = idToRGB( id.substr( 4 ) );

    useEffect( () => {
        if( game && game.hasCoverImage ) {
            ( async(): Promise<void> => {
                const urls = await getCoverThumbnailUrls( id, game.userId );

                if( !urls || !urls[ 0 ] || !urls[ 1 ] ) {
                    setCoverUrls( null );
                }
                else {
                    setCoverUrls( urls as string[] );
                }
            })();
        }
    }, [ game ] );

    const playUrl = getPlayUrl( id );

    return <Col className="librarycard" xl={4} lg={6} md={6} sm={12} xs={12}>
        <div className="inner">
            <Row noGutters>
                <Col xs="auto">
                    {coverUrls && <a href={playUrl} className="card-cover-image cover-art">
                        <picture>
                            <source srcSet={`${coverUrls[ 0 ]}, ${coverUrls[ 1 ]} 2x`} />
                            <img src={coverUrls[ 0 ]} alt={game.title} loading="lazy" />
                        </picture>
                    </a>}
                    {!coverUrls && game.hasCoverImage && <div className="cover-art loader" />}
                    {!game.hasCoverImage && <a href={playUrl} className="cover-art placeholder" style={{
                    background: `${gradientFrom} linear-gradient(220deg, ${gradientFrom} 0%, ${gradientTo} 100%)`
                }}></a>}
                </Col>

                <Col className="storydata">
                    <h3 className="title">
                        <a href={playUrl}>{game.title}</a>
                    </h3>
                    <h4 className="author">
                        by{" "}
                        <strong>{game.author}</strong>
                        {" "}
                        {game.credits && <OverlayTrigger placement="auto" overlay={
                            <Tooltip id={"credits-" + id}>
                                {game.credits}
                            </Tooltip>}>
                            <div className="credits-icon">
                                +
                            </div>
                        </OverlayTrigger>}
                    </h4>

                    <p className="blurb">{game.blurb}</p>
                </Col>
            </Row>
            <Row>
                <Col xs="auto">
                    <GameCardActionsDropdown id={id} />
                </Col>
                <Col>
                    <div className="badges">
                        {game.genres?.map( ( genreKey: string ) => <Badge key={genreKey} variant="primary" className="genre">
                            {getGenreLabel( genreKey )}
                        </Badge> )}

                        {game.language !== "English" && <Badge variant="secondary" className="language">
                            {game.language}
                        </Badge>}

                        <Badge variant="secondary" className="system">
                            {game.system}
                        </Badge>

                        {game.adult && <OverlayTrigger placement="bottom" overlay={
                            <Tooltip id={"pg-" + id}>
                                May contain elements unsuitable for children
                            </Tooltip>}>
                            <Badge variant="danger">
                                PG
                            </Badge>
                        </OverlayTrigger>}

                        {game.contentWarnings && <OverlayTrigger placement="bottom" overlay={
                            <Tooltip id={"cw-" + id}>
                                {game.contentWarnings}
                            </Tooltip>}>
                            <Badge variant="danger">
                                !
                            </Badge>
                        </OverlayTrigger>}
                    </div>
                </Col>
            </Row>
        </div>
    </Col>;
};

export default GameCard;
