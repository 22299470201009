import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";

import LoadSpinner from "components/Misc/LoadSpinner";
import NoUploads from "./NoUploads";
import UploadedGamesTable from "./UploadedGamesTable";

import { getMyGames } from "services/firebase";
import { PublicGameData, UnlistedGameData } from "../../types/game";

/**
 * Lists the games the user has uploaded
 */
const MyUploads: React.FC = () => {
    const [ publicGames, setPublicGames ] = useState<PublicGameData[] | null>( null );
    const [ unlistedGames, setUnlistedGames ] = useState<UnlistedGameData[] | null>( null );

    const refresh = async(): Promise<void> => {
        setPublicGames( null );
        setUnlistedGames( null );

        const uploads = await getMyGames();

        setPublicGames( uploads.public );
        setUnlistedGames( uploads.unlisted );
    };

    useEffect( () => { refresh(); }, [] );

    if( !publicGames || !unlistedGames ) {
        return <LoadSpinner fullscreen />;
    }

    if( publicGames.length === 0 && unlistedGames.length === 0 ) {
        return <NoUploads />;
    }

    return <Container>
        <h2>Public Games</h2>
        <UploadedGamesTable games={publicGames} refresh={refresh} />

        <h2>Unlisted Games</h2>
        <UploadedGamesTable games={unlistedGames} refresh={refresh} />
    </Container>;
};

export default MyUploads;
