import React from "react";

interface HtmlPreviewProps {
    html: string;
}

/**
 * Preview for pure HTML files
 */
const HtmlPreview: React.FC<HtmlPreviewProps> = ({ html }) => {
    return <iframe srcDoc={html} />;
};

export default HtmlPreview;
