const genreList: ReadonlyMap<string, string> = new Map( [
    [ "children", "Children's" ],
    [ "educational", "Educational" ],
    [ "fantasy", "Fantasy" ],
    [ "historical", "Historical" ],
    [ "horror", "Horror" ],
    [ "humor", "Humor" ],
    [ "mystery", "Mystery" ],
    [ "nonfiction", "Non-Fiction" ],
    [ "poetry", "Poetry" ],
    [ "romance", "Romance" ],
    [ "scifi", "Science Fiction" ],
    [ "seasonal", "Seasonal" ],
    [ "life", "Slice of Life" ],
    [ "spiritual", "Spiritual" ],
    [ "surreal", "Surreal" ]
] );

export const getGenreKeys = (): string[] => {
    return Array.from( genreList.keys() );
};

export const getGenreLabel = ( key: string ): string | undefined => {
    return genreList.get( key );
};
