import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import { Link } from "react-router-dom";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";


/**
 * Header navbar
 */
const MainNav: React.FC = () => {
    const [ user, setUser ] = useState<firebase.User | null>( null );
    const [ isLoading, setIsLoading ] = useState( true );

    const logout = (): void => {
        firebase.auth()?.signOut();
    };

    // Listen to the Firebase Auth state and set the local state
    useEffect( () => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged( userData => {
            setUser( userData );
            setIsLoading( false );
        });

        return (): void => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts
    }, [] );

    return <Navbar bg="light" expand="lg">
        <Navbar.Brand as={Link} to="/">Borogove.io</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
                <Nav.Link as={Link} to="/">
                    Browse
                </Nav.Link>
                <Nav.Link as={Link} to="/upload">
                    Upload
                </Nav.Link>
                <Nav.Link href="https://borogove.app">
                    Create
                </Nav.Link>
                <Nav.Link as={Link} to="/my-uploads">
                    My Games
                </Nav.Link>
                <Nav.Link as={Link} to="/about">
                    About
                </Nav.Link>
            </Nav>
            {!isLoading && <Nav>
                {user && <NavDropdown title={user.displayName} id="user-management-dropdown">
                    <NavDropdown.Item onClick={logout}>
                        Logout
                    </NavDropdown.Item>
                </NavDropdown>}
                {!user && <Nav.Link as={Link} to="/login">
                    Sign In
                </Nav.Link>}
            </Nav>}
        </Navbar.Collapse>
    </Navbar>;
};

export default MainNav;
