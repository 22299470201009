import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

interface DeleteConfirmationProps {
    onConfirm: () => void;
    onHide: () => void;
    show?: boolean;
}

/**
 * Are you sure you want to delete the game?
 */
const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({ onConfirm, onHide, show = false }) => {
    const [ isUnderstood, setIsUnderstood ] = useState( false );

    const toggleUnderstanding = (): void => {
        setIsUnderstood( !isUnderstood );
    };

    return <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>
                Delete Game
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
                Deleting the game will remove the uploaded files, bibliographical
                data and cover art. <strong>Deletion cannot be undone.</strong>
            </p>

            <Form.Group controlId="infoUnderstood">
                <Form.Check type="checkbox"
                            label="I understand and want to delete the game"
                            onChange={toggleUnderstanding} />
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            <Col>
                <Button variant="link" onClick={onHide}>
                    cancel
                </Button>
            </Col>
            <Col xs="auto">
                <Button variant="danger" onClick={onConfirm} disabled={!isUnderstood}>
                    Delete
                </Button>
            </Col>
        </Modal.Footer>
    </Modal>;
};

export default DeleteConfirmation;
