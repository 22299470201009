import React, { useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import FileUploadForm from "components/Form/FileUploadForm";
import LoadSpinner from "components/Misc/LoadSpinner";

import { AuthoringSystem, FileFormat } from "../../types/game";
import { getGameData, updateData, uploadFile } from "../../services/firebase";

interface UploadNewGameFileParams {
    id: string;
}

enum UploadState {
    idle,
    uploading,
    ready,
    error
}

/**
 * Replacing an existing game file with a new one
 */
const UploadNewGameFile: React.FC = () => {
    const [ fileFormat, setFileFormat ] = useState<FileFormat>( "autodetect" );
    const [ file, setFile ] = useState<File | null>( null );
    const [ isValid, setIsValid ] = useState( false );
    const [ system, setSystem ] = useState<AuthoringSystem | null>( null );
    const [ uploadState, setUploadState ] = useState<UploadState>( UploadState.idle );

    const { id } = useParams<UploadNewGameFileParams>();

    const setFileMetadata = ( fileFormat: FileFormat, system: AuthoringSystem | null ): void => {
        setFileFormat( fileFormat );
        setSystem( system );
    };

    const onSubmit = async( e: React.FormEvent ): Promise<void> => {
        e.preventDefault();

        if( !file ) {
            return;
        }

        const extension = file.name.split( "." ).pop() || "data";
        const filename = `${id}.${extension}`;

        setUploadState( UploadState.uploading );
        const gameData = await getGameData( id );
        if( !gameData ) {
            throw new Error( "Couldn't load game data" );
        }
        await uploadFile( `games/${filename}`, file );
        await updateData(
            id,
            {
                format: fileFormat,
                system,
                filename
            },
            gameData.isPublic
        );
        setUploadState( UploadState.ready );
    };

    if( uploadState === UploadState.uploading ) {
        return <LoadSpinner fullscreen />;
    }

    if( uploadState === UploadState.ready ) {
        return <Container>
            <p>
                Game file has been updated.
            </p>

            <p>
                <Link to="/my-uploads">
                    Back to My Games
                </Link>
            </p>
        </Container>;
    }

    return <Container>
        <Form onSubmit={onSubmit}>
            <Alert variant="info">
                <ul>
                    <li>
                        The old game file will be deleted permanently.
                    </li>
                    <li>
                        In most games, uploading a new version of the game will break existing save games.
                    </li>
                </ul>
            </Alert>

            <FileUploadForm file={file}
                            fileFormat={fileFormat}
                            isValid={isValid}
                            setFile={setFile}
                            setFileMetadata={setFileMetadata}
                            setValidity={setIsValid}
                            system={system} />

            <Row>
                <Col>
                    <Link to="/my-uploads">
                        Cancel
                    </Link>
                </Col>
                <Col xs="auto">
                    <Button type="submit" disabled={!isValid}>
                        Upload
                    </Button>
                </Col>
            </Row>
        </Form>
    </Container>;
};

export default UploadNewGameFile;
