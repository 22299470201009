import React from "react";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";

/**
 * Error message for when the URL is invalid when uploading from Borogove.app
 */
const InvalidIDEUploadParams: React.FC = () => <Container>
    <Alert variant="danger">
        Invalid upload parameters: unknown format or system.
    </Alert>
</Container>;

export default InvalidIDEUploadParams;
