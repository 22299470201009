import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import Container from "react-bootstrap/Container";

import LoadSpinner from "components/Misc/LoadSpinner";

import { initFirebase } from "../../services/firebase";

initFirebase();

const uiConfig = {
    signInFlow: "popup",
    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID
    ],
    callbacks: {
        signInSuccessWithAuthResult: (): false => false
    }
};

enum UserLoginStatus {
    loading,
    signedIn,
    signedOut
}

interface LoginManagerProps {
    children: React.ReactElement;
}

/**
 * Login manager
 */
const LoginManager: React.FC<LoginManagerProps> = ({ children }) => {
    const [ loginStatus, setLoginStatus ] = useState( UserLoginStatus.loading );

    // Listen to the Firebase Auth state and set the local state
    useEffect( () => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged( user => {
            setLoginStatus( user ? UserLoginStatus.signedIn : UserLoginStatus.signedOut );
        });

        return (): void => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts
    }, [] );

    switch( loginStatus ) {
        case UserLoginStatus.signedIn:
            return <>{children}</>;

        case UserLoginStatus.signedOut:
            return <Container className="text-center">
                <h2>Sign In</h2>
                <p>
                    You need to log in to upload games.
                </p>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
            </Container>;

        case UserLoginStatus.loading:
        default:
            return <LoadSpinner fullscreen />;
    }
};

export default LoginManager;
