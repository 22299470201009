import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./Dropzone.scss";

interface CoverImageUploadFieldProps {
    coverImage: File | string | null;
    setCoverImage: ( file: File | null ) => void;
}

/**
 * Cover image upload
 */
const CoverImageUploadField: React.FC<CoverImageUploadFieldProps> = ({ coverImage, setCoverImage }) => {
    const [ previewUrl, setPreviewUrl ] = useState( "" );

    const onDrop = useCallback( ( acceptedFiles: File[] ) => {
        const coverImage = acceptedFiles[ 0 ];

        setCoverImage( coverImage );

        try {
            setPreviewUrl( URL.createObjectURL( coverImage ) );
        }
        catch( e ) {
            // TODO: error handling
        }
    }, [] );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: "image/*",
        onDrop
    });

    useEffect( () => {
        if( coverImage && typeof coverImage === "string" ) {
            setPreviewUrl( coverImage );
        }

        return (): void => {
            if( previewUrl ) {
                URL.revokeObjectURL( previewUrl );
            }
        };
    }, [ coverImage ] );

    const removeCover = ( e: React.MouseEvent ): void => {
        e.stopPropagation();
        setCoverImage( null );
        setPreviewUrl( "" );
    };

    return (
        <div {...getRootProps()} className={"file-upload-field image-upload " + ( coverImage ? "preview" : "empty" )}>
            <input {...getInputProps()} />
            {isDragActive && <p>Drop cover image here...</p>}
            {!isDragActive && !coverImage && <p>Drag a cover image here or click to select</p> }
            {!isDragActive && coverImage && <img src={previewUrl} alt="Cover image" />}
            {coverImage && <Row>
                <Col>
                    <Button size="sm" variant="link" onClick={removeCover}>
                        Remove
                    </Button>
                </Col>
                <Col>
                    <Button size="sm" variant="link">
                        Change
                    </Button>
                </Col>
            </Row>}
        </div>
    );
};



export default CoverImageUploadField;
