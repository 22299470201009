import React from "react";
import Button from "react-bootstrap/Button";

import { getPlayUrl } from "../../services/firebase";
import { Link } from "react-router-dom";

interface PublishConfirmationProps {
    id: string;
    isEdit?: boolean;
    isPublic: boolean;
}

/**
 * Confirmation and info after publishing a game
 */
const PublishConfirmation: React.FC<PublishConfirmationProps> = ({ id, isEdit, isPublic }) => {
    const url = getPlayUrl( id );
    const title = ( (): string => {
        if( isEdit ) {
            return "Changes Saved";
        }

        if( isPublic ) {
            return "Game Published";
        }

        return "Game Uploaded";
    })();

    return <section>
        <h3>
            {title}
        </h3>

        <p>
            The direct link to your game is:
        </p>

        <p className="text-center">
            <Button variant="link">
                <a href={url}>
                    {url}
                </a>
            </Button>
        </p>

        <p>
            You can find the link later in the <Link to="/my-uploads">
                My Games
            </Link> page{isPublic && !isEdit && " where you can edit the game information you just entered"}.

            {isPublic && <span>{" "}
                The game is also listed in the <a href="/">public gallery</a>.
            </span>}
        </p>
    </section>;
};

export default PublishConfirmation;
