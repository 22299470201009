import React from "react";
import Form from "react-bootstrap/Form";

import RequiredMarker from "./RequiredMarker";

interface SelectVisibilityProps {
    isPublic?: boolean;
    setIsPublic: ( visibility: boolean ) => void;
    setTitle: ( title: string ) => void;
    title?: string;
}

/**
 * Visibility choice
 */
const SelectVisibility: React.FC<SelectVisibilityProps> = ({ isPublic, setIsPublic, setTitle, title }) => {
    const onTitleChange = ( e: React.ChangeEvent<HTMLInputElement> ): void => {
        setTitle( e.target.value );
    };

    return <section>
        <Form.Group>
            <Form.Label>
                Visibility
                <RequiredMarker />
            </Form.Label>
            <Form.Check type="radio" id="visibility">
                <Form.Check type="radio"
                            id="visibility-public"
                            label="Public"
                            checked={isPublic === true}
                            onChange={(): void => setIsPublic( true )} />
                <small>included in the public gallery</small>
                <Form.Check type="radio"
                            id="visibility-unlisted"
                            label="Unlisted"
                            checked={isPublic === false}
                            onChange={(): void => setIsPublic( false )} />
                <small>accessible only through a direct link</small>
            </Form.Check>
        </Form.Group>

        {isPublic === false && <Form.Group controlId="title-field">
            <Form.Label>
                Title
            </Form.Label>
            <Form.Control type="text" name="title" value={title} onChange={onTitleChange} placeholder="[untitled]" />
            <small>for distinguishing uploads on the My Games page</small>
        </Form.Group>}
    </section>;
};

export default SelectVisibility;
