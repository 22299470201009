import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";

/**
 * Screen that's shown when the user hasn't uploaded anything
 */
const NoUploads: React.FC = () => {
    return <Container className="text-center">
        <p>
            Nothing uploaded yet.
        </p>

        <p>
            <Link to="/upload">
                Upload your first game here!
            </Link>
        </p>
    </Container>;
};

export default NoUploads;
