import React, { useEffect, useRef, useState } from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

interface RenameUnlistedProps {
    oldTitle: string;
    onCancel: () => void;
    onConfirm: ( name: string ) => void;
    show?: boolean;
}

/**
 * Renaming the title of an unlisted game
 */
const RenameUnlisted: React.FC<RenameUnlistedProps> = ({ oldTitle, onCancel, onConfirm, show = false }) => {
    const [ title, setTitle ] = useState( "" );

    useEffect( () => {
        setTitle( oldTitle );
    }, [ oldTitle ] );

    const inputRef = useRef<HTMLInputElement | null>( null );

    useEffect( () => inputRef.current?.focus(), [ inputRef.current ] );

    const changeTitle = ( e: React.ChangeEvent<HTMLInputElement> ): void => {
        setTitle( ( e.target as HTMLInputElement ).value );
    };

    const onSubmit = ( e: React.FormEvent ): void => {
        e.preventDefault();
        onConfirm( title );
    };

    return <Modal show={show} onHide={onCancel}>
        <Form onSubmit={onSubmit}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Rename Unlisted Game
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="newTitle">
                    <Form.Label>
                        New title
                    </Form.Label>
                    <Form.Control as="input" name="title" value={title} onChange={changeTitle} ref={inputRef} />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Col>
                    <Button variant="link" type="button" onClick={onCancel}>
                        cancel
                    </Button>
                </Col>
                <Col xs="auto">
                    <Button variant="primary" type="submit">
                        Rename
                    </Button>
                </Col>
            </Modal.Footer>
        </Form>
    </Modal>;
};

export default RenameUnlisted;
