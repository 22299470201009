import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { unpublish } from "services/firebase";
import LoadSpinner from "components/Misc/LoadSpinner";

interface UnpublishProps {
    id: string;
}

/**
 * Removing a game from Public Gallery and making it private
 */
const Unpublish: React.FC = () => {
    const { id } = useParams<UnpublishProps>();
    const history = useHistory();
    const [ isUnderstood, setIsUnderstood ] = useState( false );
    const [ isUnpublishing, setIsUnpublishing ] = useState( false );

    const toggleUnderstanding = (): void => {
        setIsUnderstood( !isUnderstood );
    };

    const unlist = async(): Promise<void> => {
        setIsUnpublishing( true );
        await unpublish( id );
        history.push( "/my-uploads" );
    };

    if( isUnpublishing ) {
        return <LoadSpinner fullscreen />;
    }

    return <Container>
        <Alert variant="warning">
            <ul>
                <li>
                    Making the game unlisted removes it from the Public Gallery,
                    but its web address doesn't change. Anyone who has a direct
                    link to the game can still access it. If you want to prevent
                    old links from working, delete the game and re-upload it to
                    get a new link.
                </li>
                <li>
                    The cover image and bibliographical data (title, description,
                    genres etc.) will be deleted permanently. If you later want
                    to make the game public again, you'll have to retype that
                    information and reupload the cover image.
                </li>
            </ul>
        </Alert>

        <Form.Group controlId="infoUnderstood">
            <Form.Check type="checkbox"
                        label="I understand and want to remove the game from the Public Gallery"
                        onChange={toggleUnderstanding} />
        </Form.Group>

        <Row>
            <Col>
                <Button type="button"
                        variant="danger"
                        onClick={unlist}
                        disabled={!isUnderstood}>
                    Remove from Public Gallery
                </Button>
            </Col>
            <Col>
                <Link to="/my-uploads">
                    Cancel
                </Link>
            </Col>
        </Row>
    </Container>;
};

export default Unpublish;
