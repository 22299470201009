import React from "react";
import {
    BrowserRouter,
    Route,
    Switch
} from "react-router-dom";

import About from "components/Docs/About";
import EditBibliographicalData from "components/Edit/EditBibliographicalData";
import GameUploadForm from "components/Form/GameUploadForm";
import Listing from "components/Library/Listing";
import LoginManager from "components/User/LoginManager";
import LoginStatus from "components/User/LoginStatus";
import MainNav from "components/Navigation/MainNav";
import MyUploads from "components/Library/MyUploads";
import PrivacyPolicy from "components/Docs/PrivacyPolicy";
import PublishUnlisted from "components/Edit/PublishUnlisted";
import TOS from "components/Docs/TOS";
import Unpublish from "components/Edit/Unpublish";
import UploadNewGameFile from "components/Edit/UploadNewGameFile";

const Router: React.FC = () => {
    return <BrowserRouter>
        <MainNav />
        <Switch>
            <Route path="/about">
                <About />
            </Route>
            <Route path="/edit/:id">
                <LoginManager>
                    <EditBibliographicalData />
                </LoginManager>
            </Route>
            <Route path="/login">
                <LoginManager>
                    <LoginStatus />
                </LoginManager>
            </Route>
            <Route path="/my-uploads">
                <LoginManager>
                    <MyUploads />
                </LoginManager>
            </Route>
            <Route path="/privacy">
                <PrivacyPolicy />
            </Route>
            <Route path="/publish/:id">
                <LoginManager>
                    <PublishUnlisted />
                </LoginManager>
            </Route>
            <Route path="/reupload/:id">
                <LoginManager>
                    <UploadNewGameFile />
                </LoginManager>
            </Route>
            <Route path="/tos">
                <TOS />
            </Route>
            <Route path="/unlist/:id">
                <LoginManager>
                    <Unpublish />
                </LoginManager>
            </Route>
            <Route path="/upload">
                <LoginManager>
                    <GameUploadForm />
                </LoginManager>
            </Route>
            <Route path="/ide-upload/:fileId/:format/:system">
                <LoginManager>
                    <GameUploadForm />
                </LoginManager>
            </Route>
            <Route path="/">
                <Listing />
            </Route>
        </Switch>
    </BrowserRouter>;
};

export default Router;
