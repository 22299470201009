import React from "react";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";

interface ErrorMessageProps {
    children: React.ReactNode;
}

/**
 * Generic error message container
 */
const ErrorMessage: React.FC<ErrorMessageProps> = ({ children }) => <Container>
    <Alert variant="danger">
        {children}
    </Alert>
</Container>;

export default ErrorMessage;
