import React from "react";
import Container from "react-bootstrap/Container";

export const TOS: React.FC = () => <Container>
    <article>
        <h2>
            Terms of Service
        </h2>

        <section>
            <p>
                By using this Service you agree to the terms listed in this document.
            </p>

            <p>
                We reserve the right to modify or terminate the Service or your access to the Service for any reason, without notice, at any time, and without liability to you.
            </p>
        </section>

        <section>
            <h3>
                Acceptable Use
            </h3>

            <p>
                The purpose of the Service is to host Interactive Fiction games.
                Content that can't be considered Interactive Fiction or a game by any reasonable definition may be removed without notice.
            </p>

            <p>
                The following is a list of explicitly prohibited actions that may result in removal of content:
            </p>

            <ul>
                <li>
                    Publishing unlawful, misleading, malicious, or discriminatory content
                </li>
                <li>
                    Spamming, i.e. publishing content whose only purpose is to promote a product or service
                </li>
                <li>
                    Publishing content that promotes or participates in racial intolerance, sexism, hate crimes, hate speech,
                    intolerance to any single individual or groups of individuals, or promotes or incites criminal activity or self-harm
                </li>
                <li>
                    Publishing content that without permission claims or implies that it was created by or is endorsed by this web site, another individual, or a third party
                </li>
                <li>
                    Violating copyright by publishing copyrighted content without explicit permission from the copyright holders
                </li>
                <li>
                    Hacking, phishing, or spreading malware, or misleading other users to become victims of such activities
                </li>
                <li>
                    Hacking, maliciously manipulating, or misrepresenting this web site's interface in any way
                </li>
            </ul>

            <p>
                We reserve the right to remove the content you have published at any time without notice if you abuse the Service or violate these terms or for any other reason.
                You are responsible for the content you have published and subject to any applicable legal restrictions.
            </p>
        </section>

        <section>
            <h3>
                Rights
            </h3>

            <p>
                We claim no rights over your content. However, you are not allowed to claim that this web site or its authors endorse your content in any way unless we have agreed otherwise in writing.
            </p>

            <p>
                By publishing your content, you grant anyone the right to view and play the content free of charge via this web site or associated service.
            </p>

            <p>
                We will not sell or authorise third parties to sell your content without your written consent.
            </p>
        </section>

        <section>
            <h3>
                Warranty
            </h3>

            <p>
                This web site and Service is provided "as is", without warranty of any kind, express or implied.
                In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
                whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
                or the use or other dealings in the website or related services.
            </p>
        </section>

        {process.env.REACT_APP_FLAG_CONTENT_FORM_URL && <section>
            <h3>
                Requests for removal
            </h3>

            <p>
                To request the removal of content that violates these conditions, please fill
                out <a href={process.env.REACT_APP_FLAG_CONTENT_FORM_URL.split( "{id}" ).join( "" )} rel="noreferrer" target="_blank">this form</a>.
            </p>
        </section>}
    </article>
</Container>;

export default TOS;
