import React from "react";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";

import "./LoadSpinner.scss";

interface LoadSpinnerProps {
    fullscreen?: boolean;
}

/**
 * Generic load spinner
 */
const LoadSpinner: React.FC<LoadSpinnerProps> = ({ fullscreen = false }) => {
    const spinner = <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
    </Spinner>;

    if( fullscreen ) {
        return <Container className="load-spinner-container fullscreen">
            {spinner}
        </Container>;
    }

    return spinner;
};

export default LoadSpinner;

