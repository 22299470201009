import React from "react";
import Alert from "react-bootstrap/Alert";

interface IDEFileInfoProps {
    system: string | null;
}

/**
 * Shows info about the file uploaded from the IDE
 */
const IDEFileInfo: React.FC<IDEFileInfoProps> = ({ system }) => {
    return <section>
        <Alert variant="secondary">
            {system} game file upload via the Borogove.app editor
        </Alert>
    </section>;
};

export default IDEFileInfo;
