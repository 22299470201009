import React from "react";
import Container from "react-bootstrap/Container";

const LAST_UPDATED = "June 3rd 2021";

export const PrivacyPolicy: React.FC = () => <Container>
    <article>
        <h2>
            Privacy Policy
        </h2>

        <section>
            <h3>
                Hosted Content
            </h3>

            <p>
                The Service includes hosted content. This Privacy Policy applies only to the Service itself and not to the hosted content.
                The hosted content falls under the hosted content author's privacy policy and may collect information that is not described in this document.
            </p>
        </section>

        <section>
            <h3>
                Collected Information
            </h3>

            <p>
                We collect the following information:
            </p>

            <ol>
                <li>
                    The information and files provided by the user when uploading a game to the Service
                </li>
                <li>
                    Play statistics when playing the uploaded games, including the user's IP address
                </li>
                <li>
                    Account information of users who have logged in, including the user's email address
                </li>
            </ol>
        </section>

        <section>
            <h3>
                Removal of Information
            </h3>

            <p>
                Users may request the removal of all their information from the Service.
                This includes their user account data and the files and game data uploaded to the Service.
            </p>
        </section>

        <section>
            <h3>
                Data Storage
            </h3>

            <p>
                Data is stored outside the EU.
            </p>

            <hr />

            <p>
                This document was last updated on {LAST_UPDATED}.
            </p>
        </section>
    </article>
</Container>;

export default PrivacyPolicy;
