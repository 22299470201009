import { AuthoringSystem, FileFormat } from "types/game";
import isTextureFile from "./format-detectors/texture-detector";
import isTwineFile from "./format-detectors/twine-detector";

export const detectFormat = async( file: File ): Promise<FileFormat | null> => {
    const { name } = file;
    const extension = name.split( "." ).pop()?.toLowerCase() || "";
    const contents = await file.text();

    if( isTextureFile( contents, extension ) ) {
        return "texture";
    }

    if( isTwineFile( contents, extension ) ) {
        return "twine";
    }

    switch( extension ) {
        case "aastory":
            return "aamachine";

        case "gblorb":
        case "ulx":
            return "glulx";

        case "hex":
            return "hugo";

        case "htm":
        case "html":
            return "html";

        case "json":
            return "ink";

        case "gam":
        case "t3":
            return "tads";

        case "z3":
        case "z4":
        case "z5":
        case "z8":
        case "zblorb":
            return "z";
    }

    return null;
};

export const getExtensions = ( format: FileFormat ): string[] | null => {
    switch( format ) {
        case "glulx":
            return [ "gblorb", "ulx" ];

        case "html":
        case "twine":
            return [ "html", "htm" ];

        case "ink":
            return [ "json", "html", "htm" ];

        case "hugo":
            return [ "hex" ];

        case "tads":
            return [ "gam", "t3" ];

        case "texture":
            return [ "texture", "json", "html", "htm" ];

        case "z":
            return [ "z3", "z4", "z5", "z8", "zblorb" ];

        case "aamachine":
            return [ "aastory" ];
    }

    return null;
};


export const fileFormats: { [key in FileFormat]: { name: string; systems: AuthoringSystem[]; defaultSystem?: AuthoringSystem; } } = {
    autodetect: { name: "[autodetect]", systems: [] },
    glulx: {
        name: "Glulx",
        systems: [ "Inform 6", "Inform 7", "other" ],
        defaultSystem: "Inform 7"
    },
    html: {
        name: "HTML",
        systems: [ "other" ]
    },
    hugo: {
        name: "Hugo",
        systems: [ "Hugo" ]
    },
    ink: {
        name: "Ink",
        systems: [ "Ink" ]
    },
    tads: {
        name: "TADS",
        systems: [ "TADS 2", "TADS 3" ],
        defaultSystem: "TADS 3"
    },
    texture: {
        name: "Texture",
        systems: [ "Texture" ]
    },
    twine: {
        name: "Twine",
        systems: [ "Twine" ]
    },
    z: {
        name: "Z-machine",
        systems: [ "Dialog", "Inform 6", "Inform 7", "ZIL", "other" ],
        defaultSystem: "Inform 6"
    },
    aamachine: {
        name: "Å-machine",
        systems: [ "Dialog" ]
    }
};

export const getDefaultSystem = ( format: FileFormat | null ): AuthoringSystem | null => {
    if( !format ) {
        return null;
    }

    return fileFormats[ format ]?.defaultSystem || fileFormats[ format ]?.systems[ 0 ] || null;
};


/**
 * Checks that an authoring system exists
 */
export const validateSystem = ( system: string ): boolean => {
    return ( Object.keys( fileFormats ) as FileFormat[] )
        .map( ( id: FileFormat ) => fileFormats[id].systems.includes( system as AuthoringSystem ) )
        .includes( true );
};


/**
 * Checks that system and format exist and match
 */
export const isValidSystemAndFormat = ( system?: string, format?: string ): boolean => {
    if( !system || !format ) {
        return false;
    }

    const systems = fileFormats[format as FileFormat]?.systems;

    if( !systems ) {
        return false;
    }

    return systems.includes( system as AuthoringSystem );
};
