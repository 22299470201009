import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";

import DeleteConfirmation from "./DeleteConfirmation";
import RenameUnlisted from "./RenameUnlisted";

import { getDownloadUrl, removeGame, renameUnlistedGame } from "services/firebase";

interface PublishedGameActionsDropdownProps {
    gameId: string;
    isPublic?: boolean;
    refresh: () => void;
    setIsLoading: ( state: boolean ) => void;
    title?: string;
}

/**
 * Actions available for a game in a dropdown
 */
const PublishedGameActionsDropdown: React.FC<PublishedGameActionsDropdownProps> = ({ gameId, isPublic = false, refresh, setIsLoading, title = "" }) => {
    const [ deleteConfirmation, setDeleteConfirmation ] = useState( false );
    const [ renameModal, setRenameModal ] = useState( false );

    const remove = async(): Promise<void> => {
        setIsLoading( true );
        await removeGame( gameId || "", isPublic );
        setIsLoading( false );
        toggleDeleteConfirmation();
        refresh();
    };

    const rename = async( newTitle: string ): Promise<void> => {
        setIsLoading( true );
        renameUnlistedGame( gameId, newTitle );
        setIsLoading( false );
        toggleRenameModal();
        refresh();
    };

    const toggleDeleteConfirmation = (): void => {
        setDeleteConfirmation( prevState => !prevState );
    };

    const toggleRenameModal = (): void => {
        setRenameModal( prevState => !prevState );
    };

    return <>
        <Dropdown>
            <Dropdown.Toggle size="sm" variant="outline-primary" />

            <Dropdown.Menu>
                <Dropdown.Item href={getDownloadUrl( gameId )}>
                    Download game file
                </Dropdown.Item>
                {isPublic && <Dropdown.Item href={`/edit/${gameId}`}>
                    Edit game info or cover image
                </Dropdown.Item>}
                <Dropdown.Item href={`/reupload/${gameId}`}>
                    Upload new game file
                </Dropdown.Item>
                {isPublic && <Dropdown.Item href={`/unlist/${gameId}`}>
                    Remove from Public Gallery
                </Dropdown.Item>}
                {!isPublic && <Dropdown.Item onClick={toggleRenameModal}>
                    Rename
                </Dropdown.Item>}
                {!isPublic && <Dropdown.Item href={`/publish/${gameId}`}>
                    Publish in Public Gallery
                </Dropdown.Item>}

                <Dropdown.Divider />

                <Dropdown.Item onClick={toggleDeleteConfirmation}>
                    Delete
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>

        <RenameUnlisted oldTitle={title}
                        onCancel={toggleRenameModal}
                        onConfirm={rename}
                        show={renameModal} />
        <DeleteConfirmation onConfirm={remove}
                            onHide={toggleDeleteConfirmation}
                            show={deleteConfirmation} />
    </>;
};

export default PublishedGameActionsDropdown;
