import React from "react";

interface CharsRemainingProps {
    remaining: number;
}

/**
 * Shows how many characters are left (or over the limit) in an input field.
 */
const CharsRemaining: React.FC<CharsRemainingProps> = ({ remaining }) => {
    return <div>
        {remaining >= 0 && remaining <= 20 && <small>{remaining} character{remaining === 1 ? "" : "s"} remaining</small>}
        {remaining < 0 && <small className="text-danger">{-remaining} character{remaining === -1 ? "" : "s"} over the limit</small>}
    </div>;
};

export default CharsRemaining;
