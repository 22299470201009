import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import GameCard from "./GameCard";
import LoadSpinner from "components/Misc/LoadSpinner";

import { getGames } from "../../services/firebase";
import { PublicGameData } from "../../types/game";

import "./Listing.scss";

/**
 * Game listing
 */
const Listing: React.FC = () => {
    const [ games, setGames ] = useState<{ id: string, data: PublicGameData}[] | null>( null );
    useEffect( () => {
        getGames().then( setGames );
    }, [] );

    if( !games ) {
        return <LoadSpinner fullscreen />;
    }

    return <Container id="game-listing" fluid>
        <Row>
            {games.map( ({ id, data }) => <GameCard key={id} id={id} game={data} /> )}
        </Row>
    </Container>;
};

export default Listing;
