import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import BibliographicalDataForm from "./BibliographicalDataForm";
import InvalidIDEUploadParams from "./InvalidIDEUploadParams";
import PublishConfirmation from "./PublishConfirmation";
import PublishingScreen from "./PublishingScreen";
import RequiredMarker from "./RequiredMarker";
import UploadError from "./UploadError";
import UploadPhase from "./UploadPhase";
import UploadPreview from "./Preview/UploadPreview";

import { insertData, uploadFile } from "services/firebase";
import { getDefaultSystem, isValidSystemAndFormat } from "services/formats";
import { AuthoringSystem, FileFormat, GameData, PublicGameData } from "types/game";

enum UploadStage {
    Files,
    Preview,
    BibliographicalData,
    Publishing,
    Finished,
    Error
}

interface GameUploadFormProps {
    fileId?: string;
    format?: FileFormat;
    system?: AuthoringSystem;
}

/**
 * Form controller for uploading games
 */
const GameUploadForm: React.FC = () => {
    const { fileId, format: formatParam, system: systemParam } = useParams<GameUploadFormProps>();

    const [ data, setData ] = useState<GameData>({
        adult: false,
        author: "",
        blurb: "",
        contentWarnings: "",
        credits: "",
        format: "autodetect",
        filename: "",
        genres: [],
        language: "English",
        system: null,
        title: "",
        userId: ""
    });

    const [ coverImage, setCoverImage ] = useState<File | null>( null );
    const [ stage, setStage ] = useState<UploadStage>( UploadStage.Files );
    const [ file, setFile ] = useState<File | null>( null );
    const [ isValid, setIsValid ] = useState( false );
    const [ gameId, setGameId ] = useState<string>( "" );

    useEffect( () => {
        if( !formatParam || !systemParam ) {
            return;
        }

        // Set the format and system if they're passed in the URL
        setData( data => ({
            ...data,
            format: formatParam as FileFormat,
            system: systemParam as AuthoringSystem
        }) );
    }, [ formatParam, systemParam ] );

    const isDirectUpload = !!fileId;

    const isLastPage = (): boolean => {
        if( data.isPublic ) {
            return stage === UploadStage.BibliographicalData;
        }

        if( canFileBePreviewed() ) {
            return stage === UploadStage.Preview;
        }

        return true;
    };

    const setFileMetadata = ( fileFormat: FileFormat, system: AuthoringSystem | null ): void => setData( prevData => ({ ...prevData, format: fileFormat, system }) );
    const setIsPublic = ( isPublic: boolean ): void => setData( prevData => ({ ...prevData, isPublic }) );

    const canFileBePreviewed = (): boolean => {
        return data.format === "html" || data.format === "twine";
    };

    const isHTMLFile = (): boolean => {
        return data.format === "html" || data.format === "twine";
    };

    const nextPage = (): void => {
        let nextStage = stage + 1;

        if( nextStage === UploadStage.Preview && !canFileBePreviewed() ) {
            nextStage++;
        }

        setStage( nextStage );
    };

    const previousPage = (): void => {
        let nextStage = stage - 1;

        if( nextStage === UploadStage.Preview && !canFileBePreviewed() ) {
            nextStage--;
        }

        if( nextStage >= 0 ) {
            setStage( nextStage );
        }
    };

    const postData = async(): Promise<void> => {
        const dbData = { ...data };

        setStage( UploadStage.Publishing );

        if( dbData.isPublic ) {
            ( dbData as PublicGameData ).hasCoverImage = Boolean( coverImage );
        }

        const extension = file?.name.split( "." ).pop() || "data";

        if( !dbData.system ) {
            dbData.system = getDefaultSystem( dbData.format );
        }

        // Texture files can be .texture JSON files or full HTML files
        if( dbData.format === "texture" ) {
            if( extension === "html" || extension === "htm" ) {
                dbData.format = "html";
            }
        }

        const id = await insertData( dbData, extension, fileId );

        if( !id ) {
            setStage( UploadStage.Error );
            return;
        }

        const uploads: Promise<void>[] = [];

        if( file ) {
            uploads.push( uploadFile( `games/${id}.${extension}`, file ) );
        }

        if( coverImage ) {
            uploads.push( uploadFile( `covers/${id}`, coverImage ) );
        }

        await Promise.all( uploads );

        // TODO: error handling

        setStage( UploadStage.Finished );
        setGameId( id || "" );
    };

    const onSubmit = ( e: React.FormEvent ): void => {
        e.preventDefault();

        if( isLastPage() ) {
            postData();
        }
        else {
            nextPage();
        }
    };

    const setTitle = ( title: string ): void => {
        setData( prevData => ({
            ...prevData,
            title
        }) );
    };

    if( fileId && !isValidSystemAndFormat( systemParam, formatParam ) ) {
        return <InvalidIDEUploadParams />;
    }

    return <Container>
        <Form onSubmit={onSubmit}>
            {stage === UploadStage.Files && <UploadPhase data={data}
                                                         file={file}
                                                         fileId={fileId}
                                                         setFile={setFile}
                                                         setFileMetadata={setFileMetadata}
                                                         setIsPublic={setIsPublic}
                                                         setTitle={setTitle}
                                                         setValidity={setIsValid} />}
            {stage === UploadStage.Preview && file && <UploadPreview file={file}
                                                                     format={data.format}
                                                                     isValid={isValid}
                                                                     setValidity={setIsValid} />}
            {stage === UploadStage.BibliographicalData && <BibliographicalDataForm coverImage={coverImage}
                                                                                   data={data as PublicGameData}
                                                                                   file={isHTMLFile() ? file : null}
                                                                                   setCoverImage={setCoverImage}
                                                                                   setData={setData}
                                                                                   setValidity={setIsValid} />}
            {stage === UploadStage.Publishing && <PublishingScreen />}
            {stage === UploadStage.Finished && <PublishConfirmation id={gameId} isPublic={Boolean( data.isPublic )} />}
            {stage === UploadStage.Error && <UploadError />}

            {stage < UploadStage.Publishing && <Row>
                <Col>
                    {stage !== UploadStage.Files && <Button type="button" onClick={previousPage}>
                        &lt; Previous
                    </Button>}
                </Col>
                <Col xs="auto">
                    <Button type="submit" disabled={!isValid}>
                        {isLastPage() ? "Upload" : "Next >"}
                    </Button>
                </Col>
                <Col>
                    {!isValid && !isDirectUpload && <em className="small">
                        {stage === UploadStage.Files
                            ? <span>Choose the game file and fill in the form</span>
                            : <span>Fill out all required fields marked with <RequiredMarker /></span>}
                    </em>}
                </Col>
            </Row>}
        </Form>
    </Container>;
};

export default GameUploadForm;
