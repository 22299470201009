const isTwineFile = ( contents: string, extension: string ): boolean => {
    if( extension !== "html" && extension !== "htm" ) {
        return false;
    }

    // probably gets a couple of false positives, but trying to detect all Twine formats would lead to more false negatives
    return contents.toLowerCase().includes( "twine" );
};

export default isTwineFile;
