import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";

import { getPlayUrl } from "services/firebase";

import { PublicGameData, UnlistedGameData } from "../../types/game";
import PublishedGameActionsDropdown from "./PublishedGameActionsDropdown";

interface UploadedGamesTableProps {
    games: Array<PublicGameData | UnlistedGameData>;
    refresh: () => void;
}

/**
 * Table of uploaded games
 */
const UploadedGamesTable: React.FC<UploadedGamesTableProps> = ({ games, refresh }) => {
    const [ isLoading, setIsLoading ] = useState( false );

    if( games.length === 0 ) {
        return <p>
            <em>
                Nothing uploaded
            </em>
        </p>;
    }

    if( isLoading ) {
        return <Spinner animation="border" />;
    }

    const toDate = ( timestamp: number ): string => {
        if( !timestamp ) {
            return "-";
        }

        const date = new Date( timestamp );

        return date.toISOString().substr( 0, 10 );
    };

    return <Table striped bordered>
        <thead>
            <tr>
                <th>
                    Title
                </th>
                <th>
                    Uploaded
                </th>
                <th>
                    System
                </th>
                <th>
                    Link
                </th>
                <th>
                    Actions
                </th>
            </tr>
        </thead>
        <tbody>
            {games.map( ( game: PublicGameData | UnlistedGameData ) => <tr key={game.id}>
                <td>
                    {( "title" in game && game.title ) ? game.title : "[untitled]"}
                </td>
                <td className="text-nowrap">
                    {toDate( game.created )}
                </td>
                <td className="text-nowrap">
                    {game.system}
                </td>
                <td>
                    <a href={getPlayUrl( game.id || "" )} target="_blank" rel="noreferrer">
                        play
                    </a>
                </td>
                <td>
                    <PublishedGameActionsDropdown gameId={game.id || ""}
                                                  isPublic={game.isPublic}
                                                  refresh={refresh}
                                                  setIsLoading={setIsLoading}
                                                  title={game.title} />
                </td>
            </tr> )}
        </tbody>
    </Table>;
};

export default UploadedGamesTable;
