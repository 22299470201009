import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Button from "react-bootstrap/Button";

import RequiredMarker from "./RequiredMarker";

import "./Dropzone.scss";

interface GameFileUploadFieldProps {
    file: File | null;
    setFile: ( file: File | null ) => void;
}

const GameFileUploadField: React.FC<GameFileUploadFieldProps> = ({ file, setFile }) => {
    const onDrop = useCallback( async( acceptedFiles: File[] ) => {
        const file = acceptedFiles[ 0 ];
        setFile( file );
    }, [] );
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const removeFile = (): void => setFile( null );

    if( file ) {
        return <div className={"file-upload-field game-file-upload text-center " + ( file ? "preview" : "empty" )}>
            <p>
                {file.name}
            </p>
            <p className="reset-upload">
                <Button type="button" variant="link" onClick={removeFile}>
                    <small>
                        remove file
                    </small>
                </Button>
            </p>
        </div>;
    }

    return (
        <div {...getRootProps()} className="file-upload-field game-file-upload">
            <input {...getInputProps()} />
            {isDragActive ? <p>
                Drop the file here...
            </p> : <p>
                Drag a game file here or click to select
                <RequiredMarker />
            </p>}
        </div>
    );
};


export default GameFileUploadField;
