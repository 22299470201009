import React from "react";
import Container from "react-bootstrap/Container";
import { getUser } from "services/firebase";

/**
 * User's login status
 */
const LoginStatus: React.FC = () => {
    const user = getUser();

    return <Container>
        {user
            ? `Logged in as ${user?.displayName}`
            : "Not logged in"}
    </Container>;
};

export default LoginStatus;
