import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { getDownloadUrl } from "services/firebase";

interface GameCardActionsDropdownProps {
    id: string;
}

/**
 * Game Card actions
 */
const GameCardActionsDropdown: React.FC<GameCardActionsDropdownProps> = ({ id }) => {
    return <Dropdown>
        <Dropdown.Toggle size="sm" variant="link" />

        <Dropdown.Menu>
            <Dropdown.Item href={getDownloadUrl( id )}>
                Download game file
            </Dropdown.Item>
            {<Dropdown.Item href={`${process.env.REACT_APP_FLAG_CONTENT_FORM_URL}${id}`} target="_blank">
                Report game
            </Dropdown.Item>}
        </Dropdown.Menu>
    </Dropdown>;
};

export default GameCardActionsDropdown;
