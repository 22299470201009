import React from "react";

import "./RequiredMarker.scss";

/**
 * The marker for required fields
 */
const RequiredMarker: React.FC = () => {
    return <span className="required-field-marker" title="Required">*</span>;
};

export default RequiredMarker;
