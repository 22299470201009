import React from "react";
import Alert from "react-bootstrap/Alert";


/**
 * Generic upload error
 */
const UploadError: React.FC = () => <Alert variant="danger">
    Error uploading the game file. Please try again.
</Alert>;

export default UploadError;
