import React from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";

/**
 * About page
 */
const About: React.FC = () => {
    return <Container>
        <article>
            <h2>
                About
            </h2>

            <p>
                This is a free hosting service for Interactive Fiction games.
                You can upload games and share them with others by giving out a link to play online.
            </p>

            <p>
                Games must be either parser system story files or HTML files. Currently supported file formats:
            </p>

            <ul>
                <li>
                    HTML (including Twine and Texture)
                </li>
                <li>
                    Z-Machine (Inform, Dialog, ZIL)
                </li>
                <li>
                    Glulx (Inform)
                </li>
                <li>
                    .gam, .t3 (TADS)
                </li>
                <li>
                    HEX (Hugo)
                </li>
                <li>
                    Å-Machine (Dialog)
                </li>
                <li>
                    Ink JSON files
                </li>
            </ul>

            <p>
                Game uploads can be public or private. Public uploads can be found in the gallery on the front page.
                Private uploads aren't listed anywhere and can only be accessed through the direct link.
            </p>

            <p>
                You can play the games without logging in, but uploading requires signing up using a Google account.
            </p>

            <p>
                This service is brought to you in association with the <a href="https://iftechfoundation.org">Interactive Fiction Technology Foundation</a>.
            </p>

            <ul>
                {process.env.REACT_APP_CONTACT_FORM_URL && <li>
                    <a href={process.env.REACT_APP_CONTACT_FORM_URL}>
                        Contact form
                    </a>
                </li>}
                <li>
                    <Link to="/tos">
                        Terms of Service
                    </Link>
                </li>
                <li>
                    <Link to="/privacy">
                        Privacy Policy
                    </Link>
                </li>
            </ul>
        </article>
    </Container>;
};

export default About;
