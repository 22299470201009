import React from "react";

import LoadSpinner from "components/Misc/LoadSpinner";

/**
 * "Publishing..." screen
 */
const PublishingScreen: React.FC = () => {
    return <div id="publishing-screen">
        <h3>
            Uploading...
        </h3>

        <LoadSpinner fullscreen />
    </div>;
};

export default PublishingScreen;
