import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import BibliographicalDataForm from "components/Form/BibliographicalDataForm";
import RequiredMarker from "components/Form/RequiredMarker";
import ErrorMessage from "components/Misc/ErrorMessage";
import LoadSpinner from "components/Misc/LoadSpinner";

import { getCoverImageUrl, getGameData, updateData, uploadFile } from "services/firebase";
import { PublicGameData, GameData } from "types/game";
import PublishConfirmation from "../Form/PublishConfirmation";

interface EditBibliograhicalDataParams {
    id: string;
}

enum GameDataLoadState {
    loading,
    ready,
    saving,
    saved,
    error
}

/**
 * Editing a game's meta information
 */
const EditBibliographicalData: React.FC = () => {
    const { id } = useParams<EditBibliograhicalDataParams>();
    const [ coverImage, setCoverImage ] = useState<File | string | null>( null );
    const [ data, setData ] = useState<PublicGameData | null>( null );
    const [ isValid, setIsValid ] = useState( true );
    const [ loadState, setLoadState ] = useState( GameDataLoadState.loading );

    const loadData = async(): Promise<void> => {
        setLoadState( GameDataLoadState.loading );
        const gameData = await getGameData( id );

        if( gameData?.hasCoverImage ) {
            const url = await getCoverImageUrl( id, gameData.userId );
            setCoverImage( url || null );
        }

        setData( gameData );
        setLoadState( gameData ? GameDataLoadState.ready : GameDataLoadState.error );
    };

    useEffect( () => {
        loadData();
    }, [ id ] );

    if( !data || loadState === GameDataLoadState.loading || loadState === GameDataLoadState.saving ) {
        return <LoadSpinner fullscreen />;
    }

    if( loadState === GameDataLoadState.error ) {
        return <ErrorMessage>
            Couldn't load game data
        </ErrorMessage>;
    }

    if( loadState === GameDataLoadState.saved ) {
        return <Container>
            <PublishConfirmation id={id} isPublic={data.isPublic} isEdit />
        </Container>;
    }

    const onSubmit = async( e: React.FormEvent ): Promise<void> => {
        e.preventDefault();
        setLoadState( GameDataLoadState.saving );

        if( coverImage && typeof coverImage !== "string" ) {
            await uploadFile( `covers/${id}`, coverImage );
        }

        await updateData(
            id,
            {
                ...data,
                hasCoverImage: Boolean( coverImage )
            },
            data.isPublic
        );

        // TODO error handling

        setLoadState( GameDataLoadState.saved );
    };

    return <Container>
        <Form onSubmit={onSubmit}>
            <BibliographicalDataForm coverImage={coverImage}
                                     data={data}
                                     setCoverImage={setCoverImage}
                                     setData={( x: GameData | React.SetStateAction<GameData> ): void => setData( x as PublicGameData )}
                                     setValidity={setIsValid}
                                     termsAccepted />

            <Row>
                <Col>
                    <Link to="/my-uploads">
                        Cancel
                    </Link>
                </Col>
                <Col xs="auto">
                    <Button type="submit" disabled={!isValid}>
                        Save changes
                    </Button>
                </Col>
                <Col>
                    {!isValid && <em>Fill out all required fields marked with <RequiredMarker /></em>}
                </Col>
            </Row>
        </Form>
    </Container>;
};

export default EditBibliographicalData;
