import React, { ChangeEvent, useEffect, useState } from "react";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import RequiredMarker from "./RequiredMarker";

interface LanguageSelectFieldProps {
    language: string;
    setLanguage: ( language: string ) => void;
}

const defaultLanguages = [
    "English",
    "Spanish",
    "German",
    "French",
    "Italian",
    "Russian"
];

const NONE_ID = "none";
const OTHER_ID = "other";

/**
 * Language selector
 */
const LanguageSelectField: React.FC<LanguageSelectFieldProps> = ({ language, setLanguage }) => {
    const [ selectedLanguage, setSelectedLanguage ] = useState( "" );
    const [ customLanguage, setCustomLanguage ] = useState( "" );
    const [ otherSelected, setOtherSelected ] = useState( false );

    const onChange = ( e: ChangeEvent<HTMLInputElement> ): void => {
        const { value } = e.target;

        setSelectedLanguage( value );

        if( value === OTHER_ID ) {
            setLanguage( customLanguage );
        }
        else {
            setLanguage( value );
        }
    };

    const onChangeCustom = ( e: ChangeEvent<HTMLInputElement> ): void => {
        const { value } = e.target;

        setCustomLanguage( value );
        setLanguage( value );
    };

    useEffect( () => {
        if( defaultLanguages.includes( language ) || language === NONE_ID ) {
            setOtherSelected( false );
            setSelectedLanguage( language );
        }
        else {
            setOtherSelected( true );
            setSelectedLanguage( OTHER_ID );
            setCustomLanguage( language );
        }
    }, [ language ] );

    return <Row>
        <Col>
            <Form.Group>
                <Form.Label>
                    Language
                    {" "}
                    <RequiredMarker />
                </Form.Label>
                <Form.Control as="select" value={selectedLanguage} onChange={onChange}>
                    {defaultLanguages.map( ( lang: string ) => <option key={lang} value={lang}>
                        {lang}
                    </option> )}
                    <option value={NONE_ID}>
                        None / textless
                    </option>
                    <option value={OTHER_ID}>
                        other...
                    </option>
                </Form.Control>
            </Form.Group>
        </Col>
        {otherSelected && <Col>
            <Form.Group>
                <Form.Label>
                    Language Name
                    {" "}
                    <RequiredMarker />
                </Form.Label>
                <Form.Control value={customLanguage} onChange={onChangeCustom} />
            </Form.Group>
            </Col>}
    </Row>;
};

export default LanguageSelectField;
