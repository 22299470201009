import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import { getGenreKeys, getGenreLabel } from "services/genres";


interface GenreSelectionProps {
    clearGenres: () => void;
    genres: string[];
    setGenre: ( genre: string, value: boolean ) => void;
}

/**
 * Shows the checkbox list of genres
 */
const GenreSelection: React.FC<GenreSelectionProps> = ({ clearGenres, genres = [], setGenre }) => {
    const renderColumn = ( column: string[] ): React.ReactElement => <Col key={column[0]}>
        {column.map( ( key: string ) => {
            const isChecked = genres.includes( key );
            return <Form.Check key={key}
                               type="checkbox"
                               id={"genre-" + key}
                               label={getGenreLabel( key )}
                               checked={isChecked}
                               disabled={genres.length >= 2 && !isChecked}
                               onChange={(): void => setGenre( key, !isChecked )} />;
        })}
    </Col>;

    const genreEntries = getGenreKeys();
    const sliceSize = Math.ceil( genreEntries.length / 3 );
    const genreColumns = [
        genreEntries.slice( 0, sliceSize ),
        genreEntries.slice( sliceSize, sliceSize * 2 ),
        genreEntries.slice( sliceSize * 2 )
    ];

    return <div>
        <label>Genres</label>
        <Form.Group>
            <Row>
                {genreColumns.map( ( col ) => renderColumn( col ) )}
            </Row>
            <Row>
                <Col>
                    <Form.Check type="radio"
                                id="genre-none"
                                label="other / unspecified"
                                checked={genres.length === 0}
                                onChange={clearGenres} />
                </Col>
                <Col>
                    <small>Choose up to two genres</small>
                </Col>
            </Row>
        </Form.Group>
    </div>;
};

export default GenreSelection;
