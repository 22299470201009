import React, { useEffect, useState } from "react";
import FileUploadForm from "./FileUploadForm";
import SelectVisibility from "./SelectVisibility";
import { GameData, FileFormat, AuthoringSystem } from "../../types/game";
import IDEFileInfo from "./IDEFileInfo";

interface UploadPhaseProps {
    data: GameData;
    file: File | null;
    fileId?: string;    // id of the file uploaded from IDE
    setFile: ( file: File | null ) => void;
    setFileMetadata: ( format: FileFormat, system: AuthoringSystem | null ) => void;
    setIsPublic: ( visibility: boolean ) => void;
    setTitle: ( title: string ) => void;
    setValidity: ( validity: boolean ) => void;
}

/**
 * First part of the upload form, for uploading the game
 */
const UploadPhase: React.FC<UploadPhaseProps> = ({ data, file, fileId, setFile, setFileMetadata, setIsPublic, setTitle, setValidity }) => {
    const [ isUploadValid, setIsUploadValid ] = useState( !!fileId );

    useEffect( () => {
        setValidity( isUploadValid && typeof data.isPublic === "boolean" );
    }, [ isUploadValid, data.isPublic ] );

    return <div>
        {fileId && <IDEFileInfo system={data.system} />}
        {!fileId && <FileUploadForm file={file}
                                    fileFormat={data.format}
                                    isValid={isUploadValid}
                                    setFile={setFile}
                                    setFileMetadata={setFileMetadata}
                                    setValidity={setIsUploadValid}
                                    system={data.system} />}
        <SelectVisibility isPublic={data.isPublic}
                          setIsPublic={setIsPublic}
                          setTitle={setTitle}
                          title={data.title} />
    </div>;
};

export default UploadPhase;
