import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebase/storage";
import Axios, { AxiosResponse } from "axios";

import { GameData, PublicGameData, UnlistedGameData } from "../types/game";

const firebaseConfig = {
    apiKey: "AIzaSyC2Qd1EvukPKW9ZPAoh-SBAMudP9-z4LfE",
    authDomain: "borogove-io.firebaseapp.com",
    projectId: "borogove-io",
    storageBucket: "borogove-io.appspot.com",
    messagingSenderId: "93236866839",
    appId: "1:93236866839:web:5f4867b0b4ea60d494d152",
    measurementId: "G-CHCTF7H0HW"
};

const STORAGE_USER_FOLDER = "users";
export const DB_PUBLIC_FOLDER = "public";
export const DB_UNLISTED_FOLDER = "unlisted";
const DB_LOOKUP_FOLDER = "lookup";

const getAuthHeaders = async(): Promise<{ Authorization: string }> => {
    const user = getUser();
    const idToken = await user?.getIdToken();

    return {
        Authorization: "Bearer " + idToken
    };
};

export const getCoverImageUrl = ( gameId: string, userId: string ): Promise<string | undefined> => {
    return getUserStorage( userId )?.child( `covers/${gameId}` ).getDownloadURL() as Promise<string | undefined>;
};

export const getCoverThumbnailUrls = async( gameId: string, userId: string ): Promise<( string | null )[] | null> => {
    const root = getUserStorage( userId )?.child( "covers/thumbnails" );

    if( !root ) {
        return null;
    }

    const smaller = root.child( `${gameId}_120x120` ).getDownloadURL() as Promise<string | null>;
    const larger = root.child( `${gameId}_240x240` ).getDownloadURL() as Promise<string | null>;

    return Promise.all( [ smaller, larger ] );
};

export const getGameData = async( gameId: string ): Promise<PublicGameData | null> => {
    try {
        const lookupSnapshot = await firebase.database().ref( `${DB_LOOKUP_FOLDER}/${gameId}` ).once( "value" );
        const lookupVal = lookupSnapshot.val();

        if( !lookupVal ) {
            console.error( "Lookup record not found for " + gameId );
            return null;
        }

        const snapshot = await firebase.database().ref( lookupVal.path ).once( "value" );
        const val = snapshot.val();

        return val || null;
    }
    catch( e ) {
        console.error( e );
        return null;
    }
};

export const getGames = async(): Promise<{ id: string, data: PublicGameData}[]> => {
    const snapshot = await firebase.database().ref( DB_PUBLIC_FOLDER ).once( "value" );
    const values: {[user: string]: {[id: string]: PublicGameData}} = snapshot.val();

    if( !values ) {
        return [];
    }

    const flat = Object.values( values ).reduce( ( games, self ) => ({
        ...games,
        ...self
    }), {}) as {[id: string]: PublicGameData};

    return Object.entries( flat )
        .map( obj => ({ id: obj[ 0 ], data: obj[ 1 ] }) )       // change the data structure
        .sort( ( a, b )=> b.data.created - a.data.created );    // sort so that the latest is shown first
};

export const getMyGames = async(): Promise<{public: PublicGameData[]; unlisted: UnlistedGameData[]}> => {
    const userId = getUser()?.uid;

    if( !userId ) {
        throw new Error( "User not found" );
    }

    const publicSnapshot = await firebase.database().ref( `${DB_PUBLIC_FOLDER}/${userId}` ).once( "value" );
    const unlistedSnapshot = await firebase.database().ref( `${DB_UNLISTED_FOLDER}/${userId}` ).once( "value" );
    const publicValues = publicSnapshot.val() || {};
    const unlistedValues = unlistedSnapshot.val() || {};

    return {
        public: Object.keys( publicValues ).map( id => ({
            ...publicValues[ id ],
            id
        }) ),
        unlisted: Object.keys( unlistedValues ).map( id => ({
            ...unlistedValues[ id ],
            id
        }) )
    };
};

export const getPlayUrl = ( gameId: string ): string => {
    const urlTemplate = process.env.REACT_APP_PLAY_URL;

    if( !urlTemplate ) {
        throw new Error( "PLAY_URL environment variable is not defined" );
    }

    return urlTemplate.replace( "%s", gameId );
};

export const getDownloadUrl = ( gameId: string ): string => {
    return getPlayUrl( gameId ) + "/download";
};

export const getUser = (): firebase.User | null => firebase.auth().currentUser;

export const getUserStorage = ( uid?: string ): firebase.storage.Reference | null => {
    const ref = firebase.storage().ref();
    const userId = uid || getUser()?.uid;

    if( !ref || !userId ) {
        return null;
    }

    return ref.child( `${STORAGE_USER_FOLDER}/${userId}` );
};

export const initFirebase = (): void => {
    firebase.initializeApp( firebaseConfig );

    if( location.hostname === "localhost" ) {
        firebase.database().useEmulator( "localhost", 9000 );
        firebase.storage().useEmulator( "localhost", 9199 );
        firebase.functions().useEmulator( "localhost", 5001 );
    }
};


export const insertData = async( data: GameData, extension: string, tmpFile?: string ): Promise<string | null> => {
    try {
        const response: AxiosResponse<{id: string}> = await Axios.post(
            process.env.REACT_APP_API_URL + "games/create",
            {
                data,
                extension,
                tmpFile
            },
            {
                headers: {
                    ...( await getAuthHeaders() )
                }
            }
        );

        return response.data.id;
    }
    catch( e ) {
        return null;
    }
};

export const publishUnlisted = async( id: string, data: GameData ): Promise<void> => {
    return Axios.post(
        process.env.REACT_APP_API_URL + "games/publishUnlisted",
        {
            data,
            id
        },
        {
            headers: {
                ...( await getAuthHeaders() )
            }
        }
    );
};

export const removeGame = async( gameId: string, isPublic: boolean ): Promise<void> => {
    const userId = getUser()?.uid;
    const dbPath = `${isPublic ? DB_PUBLIC_FOLDER : DB_UNLISTED_FOLDER}/${userId}/${gameId}`;
    const dbRef = firebase.database().ref( dbPath );
    const snapshot = await dbRef.once( "value" );
    const gameData = snapshot.val();
    const storage = getUserStorage();

    if( gameData.hasCoverImage ) {
        try {
            await storage?.child( `covers/${gameId}` ).delete();
        }
        catch( e ) {
            // do nothing
        }
    }

    try {
        await storage?.child( `games/${gameData.filename}` ).delete();
    }
    catch( e ) {
        // do nothing
    }

    await firebase.database().ref( `${DB_LOOKUP_FOLDER}/${gameId}` ).remove();

    return dbRef.remove();
};

export const renameUnlistedGame = async( gameId: string, title: string ): Promise<void> => {
    const userId = getUser()?.uid;
    const dbPath = `${DB_UNLISTED_FOLDER}/${userId}/${gameId}`;
    const dbRef = firebase.database().ref( dbPath );

    return dbRef.update({ title });
};

export const unpublish = async( id: string ): Promise<void> => {
    return Axios.post(
        process.env.REACT_APP_API_URL + "games/unpublish",
        {
            id
        },
        {
            headers: {
                ...( await getAuthHeaders() )
            }
        }
    );
};

export const updateData = async( id: string, data: Partial<GameData>, isPublic: boolean ): Promise<void> => {
    const userId = getUser()?.uid;

    if( !userId ) {
        return;
    }

    const path = `${isPublic ? DB_PUBLIC_FOLDER : DB_UNLISTED_FOLDER}/${userId}`;
    const ref = firebase.database().ref( path );

    await ref.child( id ).update( data );
};

export const uploadFile = async( filename: string, file: File ): Promise<void> => {
    const ref = getUserStorage()?.child( filename );

    if( !ref ) {
        throw new Error( "Can't allocate storage" );
    }

    await ref.put( file );
};
