import React, { ChangeEvent, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import CharsRemaining from "./CharsRemaining";
import CoverImageUploadField from "./CoverImageUploadField";
import GenreSelection from "./GenreSelection";
import LanguageSelectField from "./LanguageSelectField";
import RequiredMarker from "./RequiredMarker";

import { GameData, PublicGameData } from "types/game";
import { getUser } from "../../services/firebase";


interface BibliographicalDataFormProps {
    coverImage: File | string | null;
    data: PublicGameData;
    file?: File | null;
    setCoverImage: ( file: File | null ) => void;
    setData: React.Dispatch<React.SetStateAction<GameData>>;
    setValidity: ( isValid: boolean ) => void;
    termsAccepted?: boolean;    // if true, don't ask to confirm TOS
}

// NOTE: if you change these, change them also in the Firebase server-side validation (functions directory)
const MAX_LENGTH = {
    TITLE: 100,
    AUTHOR: 100,
    BLURB: 400,
    CW: 200,
    CREDITS: 400
} as const;

/**
 * Bibliographical data form for game upload
 */
const BibliographicalDataForm: React.FC<BibliographicalDataFormProps> = ({ coverImage, data, file, setCoverImage, setData, setValidity, termsAccepted = false }) => {
    const [ hasPermission, setHasPermission ] = useState( false );
    const [ titleCharsRemaining, setTitleCharsRemaining ] = useState<number>( MAX_LENGTH.TITLE );
    const [ authorCharsRemaining, setAuthorCharsRemaining ] = useState<number>( MAX_LENGTH.AUTHOR );
    const [ blurbCharsRemaining, setBlurbCharsRemaining ] = useState<number>( MAX_LENGTH.BLURB );
    const [ cwCharsRemaining, setCwCharsRemaining ] = useState<number>( MAX_LENGTH.CW );
    const [ creditsCharsRemaining, setCreditsCharsRemaining ] = useState<number>( MAX_LENGTH.CREDITS );

    // autodetect author and title
    useEffect( () => {
        const author = getUser()?.displayName;

        if( file ) {
            try {
                file.text().then( contents => {
                    const titleSearch = /<title>(.*)<\/title>/i.exec( contents );

                    if( !data.title && titleSearch && titleSearch[ 1 ] ) {
                        setData( prevData => ({
                            ...prevData,
                            title: titleSearch[ 1 ]
                        }) );
                    }
                });
            }
            catch( e ) {
                // do nothing
            }
        }

        if( author && !data.author ) {
            setData( prevData => ({
                ...prevData,
                author
            }) );
        }
    }, [] );

    useEffect( () => {
        if( titleCharsRemaining < 0 || authorCharsRemaining < 0 ||  blurbCharsRemaining < 0 || cwCharsRemaining < 0 || creditsCharsRemaining < 0 ) {
            setValidity( false );
            return;
        }

        if( !termsAccepted && !hasPermission ) {
            setValidity( false );
            return;
        }

        setValidity( Boolean( data.title && data.author && data.genres && data.genres.length <= 2 ) );
    }, [
        data.title,
        data.author,
        data.genres?.length,
        titleCharsRemaining,
        authorCharsRemaining,
        blurbCharsRemaining,
        cwCharsRemaining,
        creditsCharsRemaining,
        hasPermission,
        termsAccepted
    ] );

    useEffect( () => {
        setTitleCharsRemaining( MAX_LENGTH.TITLE - ( data.title?.length || 0 ) );
        setAuthorCharsRemaining( MAX_LENGTH.AUTHOR - ( data.author?.length || 0 ) );
        setBlurbCharsRemaining( MAX_LENGTH.BLURB - ( data.blurb?.length || 0 ) );
        setCwCharsRemaining( MAX_LENGTH.CW - ( data.contentWarnings?.length || 0 ) );
        setCreditsCharsRemaining( MAX_LENGTH.CREDITS - ( data.credits?.length || 0 ) );
    }, [
        data.title?.length,
        data.author?.length,
        data.blurb?.length,
        data.contentWarnings?.length,
        data.credits?.length
    ] );

    const setValue = ( e: ChangeEvent<HTMLInputElement> ): void => {
        const { name, value } = e.target as HTMLInputElement;

        setData( prevData => ({
            ...prevData,
            [name]: value
        }) );
    };

    const setGenre = ( genre: string, value: boolean ): void => {
        const genres = data.genres || [];
        const newGenres = [ ...genres ];

        if( value ) {
            newGenres.push( genre );
        }
        else {
            newGenres.splice( newGenres.indexOf( genre ), 1 );
        }

        setData( prevData => ({
            ...prevData,
            genres: newGenres
        }) );
    };

    const clearGenres = (): void => setData( prevData => ({ ...prevData, genres: [] }) );
    const setLanguage = ( language: string ): void => setData( prevData => ({ ...prevData, language }) );
    const toggleAdult = (): void => setData( prevData => ({ ...prevData, adult: !( prevData as PublicGameData ).adult }) );

    // Has the Children's genre been selected?
    const isChildrens = data.genres?.includes( "children" ) || false;

    return <section>
        <h2>
            Game Listing Information
        </h2>

        <Row>
            <Col sm={6}>
                <Form.Group controlId="title-field">
                    <Form.Label>
                        Title
                        <RequiredMarker />
                    </Form.Label>
                    <Form.Control type="text" name="title" value={data.title} onChange={setValue} />
                    <CharsRemaining remaining={titleCharsRemaining} />
                </Form.Group>

                <Form.Group controlId="author-field">
                    <Form.Label>
                        Author
                        <RequiredMarker />
                    </Form.Label>
                    <Form.Control type="text" name="author" value={data.author} onChange={setValue} />
                    <CharsRemaining remaining={authorCharsRemaining} />
                </Form.Group>

                <Form.Group controlId="short-desc-field">
                    <Form.Label>
                        Description
                    </Form.Label>
                    <Form.Control as="textarea" rows={5} name="blurb" value={data.blurb} onChange={setValue} />
                    <CharsRemaining remaining={blurbCharsRemaining} />
                </Form.Group>
            </Col>
            <Col sm={6}>
                <CoverImageUploadField coverImage={coverImage} setCoverImage={setCoverImage} />
            </Col>
        </Row>

        <LanguageSelectField language={data.language} setLanguage={setLanguage} />

        <GenreSelection clearGenres={clearGenres} genres={data.genres} setGenre={setGenre} />

        <Form.Group controlId="content-warnings-field">
            <Form.Label>
                Content Warnings
            </Form.Label>
            <Form.Control as="textarea" name="contentWarnings" value={data.contentWarnings} onChange={setValue}></Form.Control>
            <CharsRemaining remaining={cwCharsRemaining} />
        </Form.Group>

        <Form.Group>
            <Form.Check type="checkbox"
                        id="adult"
                        label="Game contains elements that are unsuitable for children"
                        checked={data.adult && !isChildrens}
                        disabled={isChildrens}
                        onChange={toggleAdult} />
        </Form.Group>

        <Form.Group controlId="credits-field">
            <Form.Label>
                Credits
            </Form.Label>
            <Form.Control as="textarea" name="credits" value={data.credits} onChange={setValue}></Form.Control>
            <CharsRemaining remaining={creditsCharsRemaining} />
            <small>People other than the author e.g. artists</small>
        </Form.Group>

        {!termsAccepted && <Form.Group>
            <Form.Check type="checkbox" id="has-permission">
                <Form.Check.Input type="checkbox" checked={hasPermission} onChange={(): void => setHasPermission( !hasPermission )} />
                <Form.Check.Label>
                    I have authored this game myself or I have the author's permission to publish it
                    <RequiredMarker />
                </Form.Check.Label>
            </Form.Check>
        </Form.Group>}

    </section>;
};

export default BibliographicalDataForm;
