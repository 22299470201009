import React from "react";
import Router from "./Router";

import "./App.scss";

const App: React.FC = () => {
    return <Router />;
};

export default App;
