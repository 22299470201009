import React, { ChangeEvent } from "react";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import RequiredMarker from "./RequiredMarker";

import { fileFormats, getExtensions } from "../../services/formats";

import type { AuthoringSystem, FileFormat } from "types/game";

interface FileFormatFieldProps {
    autodetection: FileFormat | null;
    isValidExtension: boolean;
    fileFormat: FileFormat | "autodetect";
    setFileFormat: ( fileFormat: FileFormat | "autodetect", system: AuthoringSystem | null ) => void;
    system: AuthoringSystem | null;
}


/**
 * Field that determines the game format
 */
const FileFormatField: React.FC<FileFormatFieldProps> = ({ autodetection, isValidExtension, fileFormat, setFileFormat, system }) => {
    const onChangeFormat = ( e: ChangeEvent<HTMLInputElement> ): void => {
        const format = e.target.value as FileFormat | "autodetect";
        const system = ( format === "autodetect" ) ? null : fileFormats[ format ].defaultSystem || fileFormats[ format ].systems[ 0 ];
        setFileFormat( format, system );
    };

    const onChangeSystem = ( e: ChangeEvent<HTMLInputElement> ): void => {
        setFileFormat( fileFormat, e.target.value as AuthoringSystem );
    };

    return <Row>
        <Col md={6}>
            <Form.Group>
                <Form.Label>
                    File Format
                    <RequiredMarker />
                </Form.Label>
                <Form.Control as="select" value={fileFormat} onChange={onChangeFormat}>
                    {Object.keys( fileFormats ).map( ( format: string ) => <option key={format} value={format}>
                        {fileFormats[ format as FileFormat ].name }
                    </option> )}
                </Form.Control>
                {!isValidExtension && <Alert variant="light" className="small">
                    Invalid file extension. Valid extensions for this format are: .{getExtensions( fileFormat )?.join( ", ." )}
                </Alert>}

                {autodetection && autodetection !== fileFormat && <Alert variant="light" className="small">
                    File format was detected as {fileFormats[ autodetection ].name}. Change it only if you're sure that the autodetection wasn't accurate.
                </Alert>}
            </Form.Group>
        </Col>
        <Col md={6}>
            {fileFormat && fileFormats[ fileFormat ].systems.length > 1 && fileFormat !== "autodetect" && <Form.Group>
                <Form.Label>
                    Authoring System
                    <RequiredMarker />
                </Form.Label>
                <Form.Control as="select" value={system || ""} onChange={onChangeSystem}>
                    {fileFormats[ fileFormat ].systems.map( ( system: string ) => <option key={system} value={system}>
                        {system}
                    </option> )}
                </Form.Control>
            </Form.Group>}
        </Col>
    </Row>;
};

export default FileFormatField;
