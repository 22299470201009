import React, { useEffect, useState } from "react";

import FileFormatField from "./FileFormatField";
import GameFileUploadField from "./GameFileUploadField";

import { detectFormat, getExtensions, getDefaultSystem } from "../../services/formats";
import { AuthoringSystem, FileFormat } from "types/game";

interface FileUploadFormProps {
    file: File | null;
    fileFormat: FileFormat;
    isValid: boolean;
    setFile: ( file: File | null ) => void;
    setFileMetadata: ( format: FileFormat, system: AuthoringSystem | null ) => void;
    setValidity: ( isValid: boolean ) => void;
    system: AuthoringSystem | null;
}

/**
 * First part of the upload form that's common for both public & unlisted publishing
 */
const FileUploadForm: React.FC<FileUploadFormProps> = ({ file, fileFormat, isValid, setFile, setFileMetadata, setValidity, system }) => {
    const [ autodetection, setAutodetection ] = useState<FileFormat | null>( null );

    useEffect( () => {
        setValidity(
            file !== null &&
            fileFormat !== "autodetect" &&
            isValidExtension()
        );
    }, [ file, fileFormat, isValid ] );

    const fileReceived = async( receivedFile: File | null ): Promise<void> => {
        const autodetectedFormat = receivedFile ? await detectFormat( receivedFile ) : null;
        const defaultSystem = getDefaultSystem( autodetectedFormat );

        setFile( receivedFile );
        setAutodetection( autodetectedFormat );
        setFileMetadata( autodetectedFormat || "autodetect", defaultSystem );
    };

    const getFileExtension = (): string | undefined => {
        return file?.name?.split( "." ).pop();
    };

    const isValidExtension = (): boolean => {
        const extension = getFileExtension();

        if( !file || !extension || fileFormat === "autodetect" ) {
            return true;
        }

        const validExtensions = getExtensions( fileFormat );

        return validExtensions?.includes( extension.toLowerCase() ) || false;
    };

    return <section>
        <h2>Game Upload</h2>

        <GameFileUploadField file={file} setFile={fileReceived} />

        <FileFormatField autodetection={autodetection}
                         fileFormat={fileFormat}
                         system={system}
                         isValidExtension={isValidExtension()}
                         setFileFormat={setFileMetadata} />
    </section>;
};

export default FileUploadForm;
