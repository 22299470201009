import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import LoadSpinner from "components/Misc/LoadSpinner";
import BibliographicalDataForm from "components/Form/BibliographicalDataForm";

import { uploadFile, publishUnlisted } from "../../services/firebase";
import { GameData, PublicGameData } from "../../types/game";

interface PublishUnlistedProps {
    id: string;
}

/**
 * Publish an unlisted game in the Public Gallery
 */
const PublishUnlisted: React.FC = () => {
    const { id } = useParams<PublishUnlistedProps>();
    const history = useHistory();

    const [ data, setData ] = useState<GameData>({
        adult: false,
        author: "",
        blurb: "",
        contentWarnings: "",
        credits: "",
        format: "autodetect",
        filename: "",
        genres: [],
        language: "English",
        system: null,
        title: "",
        userId: ""
    });

    const [ coverImage, setCoverImage ] = useState<File | null>( null );
    const [ isPublishing, setIsPublishing ] = useState( false );
    const [ isValid, setIsValid ] = useState( false );

    const publish = async( e: React.FormEvent ): Promise<void> => {
        e.preventDefault();

        const dbData = {
            ...data,
            hasCoverImage: Boolean( coverImage ),
            isPublic: true
        };

        setIsPublishing( true );
        await publishUnlisted( id, dbData );

        // TODO: error handling

        if( coverImage ) {
            await uploadFile( `covers/${id}`, coverImage );
        }

        history.push( "/my-uploads" );
    };

    if( isPublishing ) {
        return <LoadSpinner fullscreen />;
    }

    return <Container>
        <Form onSubmit={publish}>
            <BibliographicalDataForm coverImage={coverImage}
                                     data={data as PublicGameData}
                                     setCoverImage={setCoverImage}
                                     setData={setData}
                                     setValidity={setIsValid} />
            <Row>
                <Col>
                    <Link to="/my-uploads">
                        Cancel
                    </Link>
                </Col>
                <Col>
                    <Button type="submit" disabled={!isValid}>
                        Publish
                    </Button>
                </Col>
            </Row>
        </Form>
    </Container>;
};

export default PublishUnlisted;
