import React, { useEffect, useState } from "react";

import HtmlPreview from "./HtmlPreview";

import { FileFormat } from "types/game";

import "./UploadPreview.scss";

interface UploadPreviewProps {
    file: File;
    format: FileFormat | "";
    isValid: boolean;
    setValidity: ( isValid: boolean ) => void;
}

/**
 * Gameplay preview before uploading a file
 */
const UploadPreview: React.FC<UploadPreviewProps> = ({ file, format, isValid, setValidity }) => {
    const [ contents, setContents ] = useState<string | null>( null );

    useEffect( () => {
        file.text().then( setContents );
    }, [] );

    useEffect( () => {
        setValidity( true );
    }, [ isValid ] );

    if( !contents ) {
        return <div>
            Loading preview...
        </div>;
    }

    return <div>
        <h2>Preview</h2>

        <p>
            Check that the game loads correctly and press Next to continue.
        </p>

        {( format !== "twine" && format !== "html" && format !== "texture" )
            ? <p>[Preview not available for this format yet]</p>
            : ( contents && <div className="game-preview">
                <HtmlPreview html={contents} />
            </div> )}
    </div>;
};

export default UploadPreview;
